<template>
    <DxDataGrid
        class="pt-2 table-page"
        id="table-baocao-hoatdong-benxe"
        :show-borders="true"
        :data-source="Params.DataTable"
        :allow-column-resizing="true"
        ref="table_baocao_hoatdong_benxe"
        key-expr="IdBenXe"
        :remote-operations="true"
        :column-auto-width="true"
        height="calc(100vh - 170px)"
        :loadPanel="{ showIndicator: false, showPane: false, text: '' }"
        @cellHoverChanged="onCellHoverChanged"
    >
        <!-- :onContentReady="onContentReady" -->
        <DxPaging :page-size="parseInt($i18n.t('Settings.PageSize'))" />
        <DxScrolling
            :mode="$i18n.t('Settings.DxScrolling_Mode')"
            :row-rendering-mode="
                $i18n.t('Settings.DxScrolling_RowRenderingMode')
            "
        />
        <DxColumnFixing :enabled="true" />
        <DxHeaderFilter :visible="true" />
        <!-- <DxColumn
            :allowFiltering="false"
            :fixed="true"
            :sorting="false"
            alignment="left"
            data-field="STT"
            caption="STT"
            width="56"
        ></DxColumn> -->
        <!-- :cellTemplate="cellTemplateSTT" -->
        <!-- :customizeText="customizeText" -->
        <DxColumn
            :allowFiltering="false"
            :fixed="true"
            alignment="left"
            data-field="TenBenXe"
            caption="Tên bến xe"
            width="235"
        ></DxColumn>
        <slot name="dx-column"></slot>

        <DxColumn
            :allowFiltering="false"
            data-field="SoLuongXe"
            caption="Số lượng xe"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            data-field="SoLuongTuyen"
            caption="Số lượng tuyến"
        ></DxColumn>
        <DxColumn caption="Số khách" alignment="center">
            <DxColumn
                :allowFiltering="false"
                width="100"
                data-field="SoKhachXuatben"
                caption="Xuất bến (khách)"
                css-class="datagrid-header-white-space"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                width="90"
                data-field="SoKhachDenBen"
                caption="Đến bến (khách)"
                css-class="datagrid-header-white-space"
            ></DxColumn>
        </DxColumn>
        <DxColumn caption="Số chuyến xuất bến" alignment="center">
            <DxColumn
                :allowFiltering="false"
                width="110"
                data-field="SoLuotXuatBenCoDinh"
                caption="Cố định (chuyến) (1)"
                css-class="datagrid-header-white-space"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                width="110"
                data-field="SoLuotXuatBenTangCuong"
                caption="Tăng cường (chuyến) (2)"
                css-class="datagrid-header-white-space"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                width="120"
                data-field="SoChuyenXuatBenThucHien"
                caption="Thực hiện (chuyến) (1+2)"
                css-class="datagrid-header-white-space"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                width="110"
                data-field="SoChuyenXB_DuKien"
                caption="Dự kiến (chuyến)"
                css-class="datagrid-header-white-space"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                data-field="SoChuyenXB_TyLeThucHien"
                caption="Tỷ lệ thực hiện (%)"
                css-class="datagrid-header-white-space"
                width="110"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                data-field="SoChuyenXB_DangKyNghi"
                caption="Đăng ký nghỉ (chuyến)"
                width="140"
                css-class="datagrid-header-white-space"
            ></DxColumn>
        </DxColumn>
        <DxColumn caption="Số chuyến đến bến" alignment="center">
            <DxColumn
                :allowFiltering="false"
                data-field="SoLuotDenBenCoDinh"
                caption="Cố định (chuyến)"
                width="120"
                css-class="datagrid-header-white-space"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                data-field="SoLuotDenBenTangCuong"
                caption="Tăng cường (chuyến)"
                width="140"
                css-class="datagrid-header-white-space"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                data-field="SoChuyenDenBenThucHien"
                caption="Thực hiện (chuyến)"
                width="120"
                css-class="datagrid-header-white-space"
            ></DxColumn>
        </DxColumn>
        <DxColumn
            caption="Trạng thái truyền tải"
            alignment="center"
            fixed-position="right"
            :fixed="true"
        >
            <DxColumn
                :allowFiltering="false"
                data-field="NgayGianDoan"
                caption="Số ngày truyền tải gián đoạn"
                css-class="datagrid-header-white-space"
                width="150"
                data-type="int"
            ></DxColumn>
            <DxColumn
                data-field="TrangThaiTruyen"
                caption="Ngày truyền tải cuối cùng"
                css-class="datagrid-header-white-space"
                width="180"
                cell-template="status-cell"
                :header-filter="{ dataSource: dataSourceStatus }"
            ></DxColumn>
        </DxColumn>
        <!-- Hover button -->
        <DxColumn
            cssClass="column-button-hover"
            data-field="DataField"
            caption="Custom"
            width="1"
            fixed-position="right"
            :fixed="true"
            cell-template="buttons-cell"
        ></DxColumn>
        <template #buttons-cell="{ data }">
            <div class="buttons">
                <DxButton
                    id
                    icon="mdi mdi-eye"
                    @click="xemChiTiet(data.data)"
                    v-if="
                        $Helper.Common.CheckRole(
                            $i18n.t('Quyen.BaoCaoBenXe'),
                            $i18n.t('Quyen.ChiTietBaoCaoHoatDongCuaXe')
                        )
                    "
                ></DxButton>
            </div>
        </template>
        <DxSummary>
            <DxGroupItem
                column="TenBenXe"
                summary-type="count"
                display-format="{0} bến xe"
            />
            <DxGroupItem
                column="SoLuongXe"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoLuongTuyen"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoChuyenXB_DuKien"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoLuotXuatBenCoDinh"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoLuotXuatBenTangCuong"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoLuotDenBenCoDinh"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoLuotDenBenTangCuong"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoChuyenXB_TyLeThucHien"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoChuyenXB_DangKyNghi"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoKhachXuatben"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoChuyenDenBenThucHien"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoChuyenXuatBenThucHien"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoKhachDenBen"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoLuongXe"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoLuongTuyen"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoChuyenXB_DuKien"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoLuotXuatBenCoDinh"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoLuotXuatBenTangCuong"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoChuyenXB_DangKyNghi"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoChuyenXB_TyLeThucHien"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoKhachXuatben"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoKhachDenBen"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoLuotDenBenCoDinh"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoLuotDenBenTangCuong"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoChuyenXuatBenThucHien"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="SoChuyenDenBenThucHien"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxTotalItem
                column="TenBenXe"
                summary-type="count"
                :customize-text="customizeTenBenXe"
            />
        </DxSummary>
        <template #color-cells="{ data }">
            <p :style="'color:#' + data.matrangthai">{{ data.text }}</p>
        </template>
        <template #tenloai-cells="{ data }">
            <div class="tenloai-css">{{ data.data.IdLoaiBen }}</div>
        </template>
        <template #status-cell="{ data }">
            <div :style="'color:#' + data.data.ColorTrangThai">
                {{ data.data.TrangThaiTruyen }}
            </div>
        </template>
    </DxDataGrid>
</template>
<script>
import { DxDataGrid, DxButton } from "devextreme-vue";
import {
    DxColumn,
    DxPaging,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxSummary,
    DxGroupItem,
    DxTotalItem,
} from "devextreme-vue/data-grid";
export default {
    props: {
        Params: { type: Object, default: {} },
    },
    data() {
        return {
            i: 0,
            arrKey: [],
            keyOld: null,
            arrOld: [],
            sum: 0,
            sum2: 0,
        };
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxScrolling,
        DxColumnFixing,
        DxHeaderFilter,
        DxSummary,
        DxGroupItem,
        DxTotalItem,
        DxButton,
    },
    methods: {
        // cellTemplateSTT(element, info) {
        // console.log("🚀 ~ file: MainDatatable.vue ~ line 409 ~ cellTemplateSTT ~ info", info)
        //     element.textContent = thsum++;
        // },
        customizeSummary(data) {
            return data.value;
        },
        customizeTenBenXe(data) {
            return "Tổng: (" + data.value + " Bến)";
        },
        // onContentReady(e) {
        //     $(e.element)
        //         .find(".dx-datagrid-total-footer")
        //         .css("border-top", 0)
        //         .css("border-bottom", "1px solid #d3d3d3")
        //         .css("height", "48px")
        //         .css("padding-top", "0px")
        //         .insertBefore($(e.element).find(".dx-datagrid-rowsview"));
        // },
        onCellHoverChanged(e) {
            this.$Helper.Common.table({ Element: e, Buttons: true }).HoverRow();
        },
        dataSourceStatus(data) {
            let self = this;
            data.dataSource.postProcess = (results) => {
                results = self.Params.DataStatus;
                return results;
            };
        },
        xemChiTiet(data) {
            let query = {
                IdSo: data.IdSo,
                IdBenXe: data.IdBenXe,
                IdSo: data.IdSo,
                DateFrom: this.Params.AboutDays.From,
                DateTo: this.Params.AboutDays.To,
            };
            this.$router.push({
                path: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe",
                query: query,
            });
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("excel", function () {
            let init = {
                Element: self.$refs.table_baocao_hoatdong_benxe.instance,
                WorkSheet: "Danh Sách",
                FileName: "Bao-Cao-Hoat-Dong-Ben-Xe",
            };
            self.$Helper.Common.excel(init).Export();
        });
        this.emitter.on("reload", function () {
            let datagrid = self.$refs.table_baocao_hoatdong_benxe.instance;
            datagrid.clearFilter();
        });
    },
    created() {},
    beforeUnmount() {
        this.emitter.off("excel");
    },
};
</script>


<style scoped>
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="5"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="5"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="6"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="6"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="7"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="7"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="8"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="8"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="9"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="9"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="10"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="10"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="11"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="11"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="12"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="12"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="13"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="13"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="14"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="14"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="15"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="15"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="16"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="16"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="17"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="17"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="18"],
>>> .dx-datagrid-total-footer .dx-row > td[aria-colindex="18"] {
    text-align: right !important;
}
>>> .dx-header-row:nth-child(2) > td[aria-colindex="1"],
>>> .dx-header-row:nth-child(2) > td[aria-colindex="2"],
>>> .dx-header-row:nth-child(2) > td[aria-colindex="3"],
>>> .dx-header-row:nth-child(2) > td[aria-colindex="4"] {
    text-align: center !important;
}
>>> .tenloai-css {
    border: 1px solid #000;
    border-radius: 50%;
    width: 22px;
    margin: 0 auto;
    text-align: center;
}
>>> .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    font-size: 12px !important;
}
</style>
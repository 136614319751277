<template>
    <div class="container" style="padding-top:0px">
        <h1 style="border-bottom:1px solid #ddd" class="pb-1 font-20 font-medium">Thông tin bến</h1>
        <div class="row-detail mt-5">
            <div class="xs3 font-16">Tên bến xe</div>
            <div class="xs9 font-16">{{model.TenBenXe}}</div>
        </div>
        <div class="row">
            <div class="xs6 mt-5 pr-3">
                <div class="row-detail">
                    <div class="xs6 font-16 col-left">Số lượng xe</div>
                    <div class="xs6 font-16">{{model.SoLuongXe}}</div>
                </div>
            </div>
            <div class="xs6 mt-5 pl-3">
                <div class="row-detail">
                    <div class="xs6 font-16">Số lượng tuyến</div>
                    <div class="xs6 font-16">{{model.SoLuongTuyen}}</div>
                </div>
            </div>
        </div>
        <h1
            style="border-bottom:1px solid #ddd"
            class="pb-1 font-20 font-medium mt-5"
        >Số chuyến xuất bến</h1>
        <div class="row">
            <div class="xs6 mt-5 pr-3">
                <div class="row-detail">
                    <div class="xs6 font-16 col-left">Cố định</div>
                    <div class="xs6 font-16">{{model.SoLuotXuatBenCoDinh}}</div>
                </div>
            </div>
            <div class="xs6 mt-5 pl-3">
                <div class="row-detail">
                    <div class="xs6 font-16">Tăng cường</div>
                    <div class="xs6 font-16">{{model.SoLuotXuatBenTangCuong}}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="xs6 mt-5 pr-3">
                <div class="row-detail">
                    <div class="xs6 font-16 col-left">Thực hiện</div>
                    <div class="xs6 font-16">{{model.XuatBenThucHien}}</div>
                </div>
            </div>
            <div class="xs6 mt-5 pl-3">
                <div class="row-detail">
                    <div class="xs6 font-16">Dự kiến</div>
                    <div class="xs6 font-16">{{model.SoChuyenXB_DuKien}}</div>
                </div>
            </div>
        </div>
        <div class="row-detail mt-5">
            <div class="xs3 font-16">Tỷ lệ thực hiện</div>
            <div class="xs9 font-16">{{model.SoChuyenXB_TyLeThucHien}}</div>
        </div>
        <div class="row-detail mt-5">
            <div class="xs3 font-16">Đăng ký nghỉ</div>
            <div class="xs9 font-16">{{model.SoChuyenXB_DangKyNghi}}</div>
        </div>
        <h1
            style="border-bottom:1px solid #ddd"
            class="pb-1 font-20 font-medium mt-5"
        >Số chuyến đến bến</h1>
        <div class="row">
            <div class="xs6 mt-5 pr-3">
                <div class="row-detail">
                    <div class="xs6 font-16 col-left">Cố định</div>
                    <div class="xs6 font-16">{{model.SoLuotDenBenCoDinh}}</div>
                </div>
            </div>
            <div class="xs6 mt-5 pl-3">
                <div class="row-detail">
                    <div class="xs6 font-16">Tăng cường</div>
                    <div class="xs6 font-16">{{model.SoLuotDenBenTangCuong}}</div>
                </div>
            </div>
        </div>
        <div class="row-detail mt-5">
            <div class="xs3 font-16">Thực hiện</div>
            <div class="xs9 font-16">{{model.DenBenThucHien}}</div>
        </div>
        <h1 style="border-bottom:1px solid #ddd" class="pb-1 font-20 font-medium mt-5">Số khách</h1>
        <div class="row-detail mt-5">
            <div class="xs3 font-16">Xuất bến</div>
            <div class="xs9 font-16">{{model.SoKhachXuatben}}</div>
        </div>
        <div class="row-detail mt-5">
            <div class="xs3 font-16">Đến bến</div>
            <div class="xs9 font-16">{{model.SoKhachDenBen}}</div>
        </div>
        <div class="row mt-5">
            <div class="xs3" style="border-bottom:1px solid #ddd;margin-right:10px">
                <h1 class="pb-1 font-20 font-medium">Trạng thái truyền tải</h1>
            </div>
            <div
                class="xs9 font-16"
                style="border-bottom:1px solid #ddd"
                :style="'color:#'+model.ColorTrangThai"
            >{{model.TrangThaiTruyen}}</div>
        </div>
        <div class="row-detail mt-5">
            <div class="xs3 font-16">Số ngày truyền tải gián đoạn</div>
            <div class="xs9 font-16">{{model.NgayGianDoan}}</div>
        </div>
        <div class="row-detail mt-5">
            <div class="xs3 font-16">Ngày truyền tải cuối cùng</div>
            <div
                class="xs9 font-16"
                :style="'color:#'+model.ColorTrangThai"
            >{{model.TrangThaiTruyen}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        Params: { type: Object, default: () => {} },
        data_version: { type: Number, default: 0 }
    },
    data() {
        return {
            model: {
                TenBenXe: "",
                SoLuongXe: "",
                SoLuongTuyen: "",
                SoLuotXuatBenCoDinh: "",
                SoLuotXuatBenTangCuong: "",
                SoChuyenXB_TyLeThucHien: "",
                SoChuyenXB_DuKien: "",
                SoLuotDenBenCoDinh: "",
                SoLuotDenBenTangCuong: "",
                SoKhachXuatben: "",
                SoKhachDenBen: "",
                NgayGianDoan: "",
                TrangThaiTruyen: "",
                ColorTrangThai: ""
            }
        };
    },
    watch: {
        data_version: {
            handler: function() {
                console.log("data_version -> data_version", this.Params);
                if (this.Params) {
                    this.model.TenBenXe = this.Params.TenBenXe;
                    this.model.SoLuongXe = this.Params.SoLuongXe + " Xe";
                    this.model.SoLuongTuyen =
                        this.Params.SoLuongTuyen + " Tuyến";
                    this.model.SoLuotXuatBenCoDinh =
                        this.Params.SoLuotXuatBenCoDinh + " Chuyến";
                    this.model.SoLuotXuatBenTangCuong =
                        this.Params.SoLuotXuatBenTangCuong + " Chuyến";
                    this.model.SoChuyenXB_TyLeThucHien =
                        this.Params.SoChuyenXB_TyLeThucHien + " Chuyến";
                    this.model.SoChuyenXB_DuKien =
                        this.Params.SoChuyenXB_DuKien + " Chuyến";
                    this.model.SoLuotDenBenCoDinh =
                        this.Params.SoLuotDenBenCoDinh + " Chuyến";
                    this.model.SoLuotDenBenTangCuong =
                        this.Params.SoLuotDenBenTangCuong + " Chuyến";
                    this.model.SoKhachXuatben =
                        this.Params.SoKhachXuatben + " Khách";
                    this.model.SoKhachDenBen =
                        this.Params.SoKhachDenBen + " Khách";
                    this.model.NgayGianDoan =
                        this.Params.NgayGianDoan + " Ngày";
                    this.model.TrangThaiTruyen = this.Params.TrangThaiTruyen;
                    this.model.ColorTrangThai = this.Params.ColorTrangThai;
                    let denben =
                        this.Params.SoLuotDenBenCoDinh +
                        this.Params.SoLuotDenBenTangCuong;
                    let xuatben =
                        this.Params.SoLuotXuatBenCoDinh +
                        this.Params.SoLuotXuatBenTangCuong;
                    this.model.DenBenThucHien = denben + " Chuyến";
                    this.model.XuatBenThucHien = xuatben + " Chuyến";

                    console.log("this.model", this.model);
                }
            },
            immediate: true
        }
    }
};
</script>
<style scoped>
.col-left {
    margin-right: 20px;
}
</style>

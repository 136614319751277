<template>
    <div>
        <DxToolbar :height="56" class="toolbar">
            <DxItem location="before" locate-in-menu="never">
                <template #default>
                    <router :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxItem>
            <DxItem location="after">
                <template #default>
                    <search-vue
                        @search="searchs"
                        @model="modelSearch"
                        v-if="data_from_api.Type !== $i18n.t('string.benxe')"
                        id="textbox_BCHoatDongCuaBenXe_TimKiem"
                    />
                    <div v-else>
                        <DxButton
                            :icon="$i18n.t('icon.format_list_checkbox')"
                            id="button_BCHoatDongXe_Loc"
                            class="icon-list"
                        />
                        <DxButton
                            :icon="$i18n.t('icon.chart_bar')"
                            id="button_BCHoatDongCuaBenXe_BieuDo"
                            class="ml-3"
                            :hint="$i18n.t('string.chart')"
                            v-if="
                                $Helper.Common.CheckRole(
                                    $i18n.t('Quyen.BaoCaoBenXe'),
                                    $i18n.t(
                                        'Quyen.BieuDoBaoCaoHoatDongCuaBenXe'
                                    )
                                )
                            "
                            @click="
                                $router.push(
                                    '/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Ben-Xe/Bieu-Do'
                                )
                            "
                        />
                        <!-- <DxButton
                            :icon="$i18n.t('icon.detail')"
                            id="button_BCHoatDongXe_Loc"
                        /> -->
                    </div>
                </template>
            </DxItem>
        </DxToolbar>
        <div class="pl-3">
            <div
                class="row"
                :class="
                    data_from_api.Type == $i18n.t('string.benxe')
                        ? 'justify-center'
                        : 'justify-space-between'
                "
            >
                <DxValidationGroup ref="formValidation">
                    <div class="row align-center pt-2 pb-4">
                        <span class="mr-2 font-16" id="label_BCHoatDongXe_Tu"
                            >Từ</span
                        >
                        <DxDateBox
                            v-model="model.DateFrom"
                            displayFormat="dd/MM/yyyy"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            id="datebox_BCHoatDongCuaBenXe_TuNgay"
                            class="datebox-baocao"
                            stylingMode="underlined"
                            :useMaskBehavior="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Không được để trống!"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="minDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                                <DxRangeRule
                                    :max="model.DateTo"
                                    message="Từ ngày phải nhỏ hơn đến ngày"
                                />
                            </DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                        <span
                            style="
                                border: 1px solid #707070;
                                height: 2px;
                                width: 10px;
                            "
                            class="mx-2"
                        ></span>
                        <DxDateBox
                            v-model="model.DateTo"
                            displayFormat="dd/MM/yyyy"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            id="datebox_BCHoatDongCuaBenXe_DenNgay"
                            class="datebox-baocao"
                            stylingMode="underlined"
                            :useMaskBehavior="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Không được để trống!"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="minDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                                <DxRangeRule
                                    :min="model.DateFrom"
                                    message="Từ ngày phải lớn hơn đến ngày"
                                />
                            </DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                        <DxButton
                            class="ml-3"
                            :icon="$i18n.t('icon.search')"
                            text="Lọc"
                            :rtlEnabled="true"
                            styling-mode="outlined"
                            id="button_BCHoatDongCuaBenXe_Loc"
                            @click="validate"
                        />
                    </div>
                </DxValidationGroup>
                <div v-if="data_from_api.Type != $i18n.t('string.benxe')">
                    <div
                        class="row pr-1"
                        :class="
                            data_from_api.DataStatus.length % 2 != 0
                                ? 'pt-3'
                                : ''
                        "
                    >
                        <div
                            style="width: 100%"
                            v-if="data_from_api.DataStatus.length > 0"
                        >
                            <div
                                class="row align-center justify-end"
                                style="padding-top: 5px"
                            >
                                <span class="pr-2">Ghi chú:</span>
                                <div style="min-width: 410px">
                                    <div
                                        v-for="(
                                            item, index
                                        ) in data_from_api.DataStatus"
                                        :key="index"
                                        :style="
                                            data_from_api.DataStatus.length <= 3
                                                ? ''
                                                : index % 2 == 0
                                                ? 'float:left'
                                                : ''
                                        "
                                    >
                                        <i
                                            :class="$i18n.t('icon.record')"
                                            :style="
                                                'color:#' + item.matrangthai
                                            "
                                            style="vertical-align: middle"
                                        ></i>
                                        <span
                                            class="pr-3"
                                            style="vertical-align: middle"
                                            >{{ item.text }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DxButton
                            :icon="$i18n.t('icon.format_list_checkbox')"
                            id="button_BCHoatDongCuaBenXe_DanhSach"
                            class="icon-list mt-3"
                            :hint="$i18n.t('string.list')"
                        />
                        <DxButton
                            :icon="$i18n.t('icon.chart_bar')"
                            id="button_BCHoatDongCuaBenXe_BieuDo"
                            class="mt-3"
                            :hint="$i18n.t('string.chart')"
                            v-if="
                                $Helper.Common.CheckRole(
                                    $i18n.t('Quyen.BaoCaoBenXe'),
                                    $i18n.t(
                                        'Quyen.BieuDoBaoCaoHoatDongCuaBenXe'
                                    )
                                )
                            "
                            @click="
                                $router.push(
                                    '/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Ben-Xe/Bieu-Do'
                                )
                            "
                        />
                    </div>
                </div>
            </div>
            <toolbar-vue
                :Params="data_from_api"
                @selectionChange="selectionChange"
                @reload="reloadFuc"
                v-if="data_from_api.Type !== $i18n.t('string.benxe')"
            />
            <datatable-vue
                :Params="data_from_api"
                :data_version="data_version"
            />
        </div>
    </div>
</template>
<script>
import router from "@/components/_Common/Components/router";
import searchVue from "@/components/_Common/Components/Search";
import DatatableVue from "@/components/BaoCaoThongKe/BaoCaoHoatDongCuaBenXe/Datatable.vue";
import ToolbarVue from "@/components/BaoCaoThongKe/BaoCaoHoatDongCuaBenXe/Toolbar.vue";
import moment from "moment";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxDateBox, DxButton, DxValidator } from "devextreme-vue";
import { DxRangeRule, DxRequiredRule } from "devextreme-vue/data-grid";
import { DxValidationGroup } from "devextreme-vue/validation-group";
export default {
    // Permission: $PermText("DanhSach", "BCHoatDongBenXe"),
    layout: "application",
    components: {
        router,
        ToolbarVue,
        searchVue,
        DatatableVue,
        DxToolbar,
        DxItem,
        DxValidationGroup,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxRangeRule,
        DxButton,
    },
    data() {
        return {
            url: this.$i18n.t("url.HoatDongBenXeList"),
            url_benxe: this.$i18n.t("url.HoatDongBenXeTheoBenList"),
            url_capdo: this.$i18n.t("url.CapDoTaiKhoan"),
            url_select_trangthai: this.$i18n.t(
                "url.BCTrangThaiTruyenTaiTrangThai"
            ),
            data_version: 0,
            maxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            minDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            id_table: "IdBenXe",
            model: {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
                IdLoaiBens: [0],
            },
            btn: [
                {
                    name: "password",
                    location: "after",
                    options: {
                        icon: "mdi mdi-calendar",
                        type: "normal",
                        onClick: () => {},
                    },
                },
            ],
            dateValue: new Date().getTime(),
            data_from_api: {
                Type: null,
                DataSelect: {
                    SelectNhom: [],
                    SelectSo: [],
                    SelectLoaiBX: [],
                },
                DataStatus: [],
                DataTable: null,
                AboutDays: {
                    From: null,
                    To: null,
                },
            },
            breadcrumbsItems: [
                {
                    id: "breadcrums_BCHoatDongCuaBenXe_BaoCaoThongKe",
                    text: this.$i18n.t("stringRouter.nodeBCTK"),
                    disabled: true,
                    to: "",
                },
                {
                    id: "breadcrums_BCHoatDongCuaBenXe_BCHoatDongCuaBenXe",
                    text: this.$i18n.t("stringRouter.brancheBCHDBX"),
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    methods: {
        async getData() {
            try {
                let query = {
                    tuNgay: this.$Helper.ConvertToTimeTicks(
                        this.model.DateFrom
                    ),
                    denNgay: this.$Helper.ConvertToTimeTicks(this.model.DateTo),
                    idSos: this.model.IdSos,
                    idLoaiBens:
                        this.model.IdLoaiBens.length > 0
                            ? this.model.IdLoaiBens
                            : [0],
                    txtTimKiem: this.model.Search || "",
                };

                this.data_from_api.DataTable = (
                    await this.$store.dispatch(
                        "BaoCaoThongKe/Get_BaoCaoHoatDongCuaBenXe",
                        query
                    )
                ).Store;
                this.data_from_api.AboutDays.From = moment(
                    this.model.DateFrom
                ).format("MM/DD/yyyy");
                this.data_from_api.AboutDays.To = moment(
                    this.model.DateTo
                ).format("MM/DD/yyyy");
            } catch (error) {
                console.log(
                    "🚀 ~ file: index.vue ~ line 315 ~ getData ~ error",
                    error
                );
            }
        },
        async getDataTheoBen() {
            try {
                this.$startLoading;
                let query = {
                    tuNgay: this.$Helper.ConvertToTimeTicks(
                        this.model.DateFrom
                    ),
                    denNgay: this.$Helper.ConvertToTimeTicks(this.model.DateTo),
                };
                let rs = await this.$Core.Api.BaoCaoBenXe(
                    this.url_benxe,
                    query
                ).Get();
                if (rs.Data.State == "Success" && rs.Data.Data.length > 0) {
                    this.data_from_api.DataTable = rs.Data.Data[0];
                    this.data_version++;
                }
            } catch (error) {
                console.log("🚀 error", error);
            } finally {
                this.$stopLoading;
            }
        },

        async getDataStatus() {
            try {
                this.$startLoading;
                let rs = await this.$Core.Api.BaoCaoBenXe(
                    this.url_select_trangthai
                ).Get();
                if (rs.Data.State == "Success") {
                    let data = rs.Data.Data.map(
                        ({ MaTrangThai, TenTrangThai, Value = [] }) => ({
                            text: TenTrangThai,
                            value: ["ColorTrangThai", "=", MaTrangThai],
                            template: "color-cells",
                            matrangthai: MaTrangThai,
                        })
                    );
                    this.data_from_api.DataStatus = data;
                }
            } catch (error) {
                console.log("🚀 error", error);
            } finally {
                this.$stopLoading;
            }
        },
        reloadFuc() {
            this.startValue = new Date();
            this.endValue = new Date();
            this.model = {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
                IdLoaiBens: [0],
            };
            this.getData();
        },
        searchs(search) {
            this.getData();
        },
        modelSearch(search) {
            this.model.Search = search;
        },
        selectionChange(data) {
            this.model.IdLoaiBens = data.SelectLoaiBX;
            this.model.IdSos = data.SelectSo;
        },
        validate(e) {
            let result = this.$refs.formValidation.instance.validate();
            if (!!result.isValid) {
                this.getData();
            }
        },
    },
    created() {
        this.data_from_api.Type =
            this.$Core.Auth.TokenParsed?.CauHinhCu?.LoaiDonVi;
        localStorage.removeItem("data-store-key");
    },
    watch: {},
};
</script>
<style>
.datebox-baocao .dx-dropdowneditor-button {
    width: 100%;
}
.datebox-baocao
    .dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input,
.dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input {
    padding-right: unset;
    max-width: 150px;
}
</style>
<style scoped>
>>> .icon-list i {
    color: #0620ee;
}
</style>
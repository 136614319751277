<template>
    <div class="row justify-space-between">
        <div>
            <DxButton
                id="button_BaoCaoHoatDongCuaBenXe_TaiLai"
                class="ma-1"
                :icon="$i18n.t('icon.reload')"
                @click="reload()"
                style="margin-left: 6px"
                :hint="$i18n.t('string.refresh')"
            />
            <DxButton
                id="button_BaoCaoHoatDongCuaBenXe_Excel"
                class="ma-1"
                :icon="$i18n.t('icon.excel')"
                @click="emitter.emit('excel')"
                style="margin-left: 6px"
                :hint="$i18n.t('string.excel')"
            />
            <DxButton
                id="button_BaoCaoHoatDongCuaBenXe_Print"
                class="ma-1"
                :icon="$i18n.t('icon.print')"
                @click="print()"
                style="margin-left: 6px"
                :hint="$i18n.t('string.print')"
            />
        </div>
        <div>
            <div class="row justify-end align-center">
                <span
                    v-if="!!selection_so"
                    class="pr-3"
                    style="white-space: nowrap"
                    >Nhóm theo:</span
                >
                <DxSelectBox
                    v-if="!!selection_so"
                    v-model="model.SelectNhom"
                    :items="array.SelectNhom"
                    display-expr="Text"
                    value-expr="Id"
                    class="mr-2"
                    id="selectbox_BaoCaoHoatDongCuaBenXe_NhomTheo"
                    @selection-changed="selectionChangeNhom"
                    stylingMode="underlined"
                />
                <span
                    v-if="!!selection_so"
                    class="pr-3"
                    style="white-space: nowrap"
                    >Sở GTVT:</span
                >
                <get-thong-tin-tinh-thanh-vue
                    v-if="!!selection_so"
                    id="dropdownbox_BaoCaoHoatDongCuaBenXe_So"
                    @result="getThongTinTinhThanh"
                    class="mr-2"
                />
                <span
                    v-if="selection_ben"
                    class="pr-3"
                    style="white-space: nowrap"
                    >Loại bến xe:</span
                >
                <get-loai-ben-xe-vue
                    v-if="!!selection_ben"
                    id="dropdownbox_BaoCaoHoatDongCuaBenXe_LoaiBX"
                    @result="getLoaiBenXe"
                    class="mr-2"
                />
                <DxButton
                    id="button_BaoCaoHoatDongCuaBenXe_Setting"
                    class="ma-1"
                    :icon="$i18n.t('icon.setting')"
                />
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import GetThongTinTinhThanhVue from "../Get/GetThongTinTinhThanh.vue";
import GetLoaiBenXeVue from "../Get/GetLoaiBenXe.vue";
import { DxButton, DxSelectBox } from "devextreme-vue";

export default {
    props: {
        Params: { type: Object, default: {} },
    },
    components: {
        GetThongTinTinhThanhVue,
        GetLoaiBenXeVue,
        DxButton,
        DxSelectBox,
    },
    data() {
        return {
            check_load: 0,
            model: {
                SelectNhom: [],
            },
            data_props: {
                SelectSo: 0,
                SelectNhom: [],
                SelectLoaiBX: [],
            },
            array: {
                SelectNhom: [
                    { Id: 1, Text: "Sở GTVT" },
                    { Id: 2, Text: "Loại bến xe" },
                ],
            },
        };
    },
    mounted() {
        this.model.SelectNhom = this.array.SelectNhom[0].Id;
    },
    computed: {
        selection_so() {
            if (
                !!this.Params &&
                this.Params.Type == this.$i18n.t("string.tongcuc")
            ) {
                return true;
            }
            return false;
        },
        selection_ben() {
            if (
                !!this.Params &&
                (this.Params.Type == this.$i18n.t("string.tongcuc") ||
                    this.Params.Type == this.$i18n.t("string.so"))
            ) {
                return true;
            }
            return false;
        },
    },
    methods: {
        onContentReady(e) {
            var html = e.component.content();
            $(html).css("padding", "8px");
        },
        print() {
            window.print();
        },
        reload() {
            this.$emit("reload");
            this.emitter.emit("reload");
        },
        getThongTinTinhThanh(data) {
            this.data_props.SelectSo = data.Model;
            this.$emit("selectionChange", this.data_props);
        },
        getLoaiBenXe(data) {
            this.data_props.SelectLoaiBX = data.Model;
            this.$emit("selectionChange", this.data_props);
        },
        selectionChangeNhom() {
            this.emitter.emit("selection-change-groupby", this.model.SelectNhom);
        },
    },
};
</script>
<template>
    <div>
        <main-datatable-vue
            :Params="Params"
            v-if="Params.Type == $i18n.t('string.tongcuc')"
        >
            <template v-slot:dx-column>
                <DxColumn
                    :allowFiltering="false"
                    :group-index="0"
                    alignment="left"
                    data-field="TenSo"
                    caption="Tên Sở GTVT"
                    :fixed="true"
                ></DxColumn>
                <DxColumn
                    :allowFiltering="false"
                    cell-template="tenloai-cells"
                    :groupCellTemplate="groupCellTemplate"
                    alignment="left"
                    :fixed="true"
                    data-field="IdLoaiBen"
                    caption="Loại bến xe"
                ></DxColumn>
            </template>
        </main-datatable-vue>
        <main-datatable-vue
            :Params="Params"
            v-else-if="Params.Type == $i18n.t('string.so')"
        >
            <template v-slot:dx-column></template>
        </main-datatable-vue>
        <datatable-ben-xe-vue
            :Params="Params.DataTable"
            :data_version="data_version"
            v-else-if="Params.Type == $i18n.t('string.benxe')"
        />
    </div>
</template>
<script>
import MainDatatableVue from "./MainDatatable.vue";
import DatatableBenXeVue from "./DatatableBenXe.vue";
import DataGrid from "devextreme/ui/data_grid";
import {
    DxColumn,
} from "devextreme-vue/data-grid";
export default {
    props: {
        Params: { type: Object, default: {} },
        data_version: { type: Number, default: 0 },
    },
    components: {
        MainDatatableVue,
        DatatableBenXeVue,
        DataGrid,
        DxColumn,
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        groupCellTemplate(e, col) {
            e.innerText =
                "Loại " +
                col.value +
                " (" +
                col.summaryItems[0].value +
                " bến xe)";
        },
    },
    mounted() {
       this.emitter.on("selection-change-groupby", function (data) {
            if (!!data) {
                let element = document.getElementById(
                    "table-baocao-hoatdong-benxe"
                );
                let instance = DataGrid.getInstance(element);
                if (instance) {
                    if (data === 2) {
                        instance.columnOption("IdLoaiBen", "groupIndex", 0);
                        instance.columnOption("TenSo", "groupIndex", null);
                    } else {
                        instance.columnOption("TenSo", "groupIndex", 0);
                        instance.columnOption("IdLoaiBen", "groupIndex", null);
                    }
                }
            }
        });
    },
};
</script>